/* eslint-disable no-useless-escape */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
// import { appRoute as commonRoutes } from '@dom-digital-online-media/dom-app-config-sdk';

import {
  appRegex as _appRegex,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_FIELD_VALIDATION_TYPE,
  appStorage as AppStorage
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import * as yup from 'yup';

// App modes
export const appEnvMode = {
  NODE_ENV_DEV: 'development',
  NODE_ENV_PROD: 'production',
  NODE_ENV_STAGE: 'stage'
};

export const headerStringRoute = {
  DASHBOARD: 'dashboard',
  TARIFF: 'tariff',
  CHARGES: 'charges',
  HELP_AND_SERVICE: 'help-service',
  ACCOUNT: 'account'
};

// Extend routes
export const appRoute = {
  // Public Routes
  // ...commonRoutes,
  TEST: '/test',
  HOME: '',
  ACTIVATION: '/activation',
  ACTIVATION_WELCOME: '/activation-welcome',
  ACTIVATION_EMAIL_VERIFICATION: '/activation/email-verification',
  ACTIVATION_PAYMENT_CALLBACK: '/activation/payment-verification',
  ACTIVATION_LEGITIMATION: '/activation/legitimation',
  ACTIVATION_LEGITIMATION_SUCCESS: '/activation/legitimation/success',
  ACTIVATION_LEGITIMATION_FAILED: '/activation/legitimation/failed',
  NOT_FOUND: '/404',
  DEBUG: '/debug',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  LANDING_PAGE: '/',
  TEST2: '/test2',
  APP_REVIEW_REDIRECT: '/store-review',

  // Private Routes
  DASHBOARD: '/dashboard',
  BOOKABLE_DATA: '/dashboard/options',
  FORCE_PASSWORD_CHANGE: '/force-password-change',

  // USER_ACCOUNT: '/account',
  // PREFERENCES: '/account/preferences',
  // ADVANTAGES: '/account/advantages',
  // TRUE_BONUS: '/account/advantages/true-bonus',
  // BIRTHDAY: '/account/advantages/birthday',
  // INSIDE_BONUS: '/account/advantages/inside-bonus',
  // ADVANTAGE_AUTOTOPUP: '/account/advantages/auto-topup',
  // PROFILE: '/account/profile',
  // CHANGE_USER_INFO: '/account/profile/change-user-info',
  // CHANGE_PASSWORD: '/account/profile/change-password',
  // CHANGE_HOTLINE: '/account/profile/change-hotline-password',
  // TARIFF_DETAILS: '/account/tariff-details',

  // HELP_AND_SERVICE: '/help-service',
  // CONTACT: '/help-service/contact',
  // EXPLAINER_VIDEO: '/help-service/explainer-video',
  // FAQ: '/help-service/faq',
  // LEGAL: '/help-service/legal',
  // DOCUMENTS: '/help-service/legal/documents',
  // PRIVACY_DOCS: '/help-service/legal/privacy-documents',
  // IMPRINT: '/help-service/legal/imprint',
  // FOOT_NOTES: '/help-service/legal/foot-notes',
  // CANCEL_CONTRACT: '/help-service/cancel-contract',
  // LOCK_UNLOCK_SIM: '/help-service/lock-unlock-sim',

  CHARGES: '/charges',
  TOPUP_HISTORY: '/charges/topup-history',
  VOUCHER_HISTORY: '/charges/voucher-history',
  RECHARGE_CREDIT: '/charges/recharge-credit',
  RECHARGE_VOUCHER: '/charges/recharge-credit/recharge-voucher',
  SET_AUTO_TOPUP: '/charges/recharge-credit/setup-auto-topup',
  SET_DIRECT_TOPUP: '/charges/recharge-credit/setup-direct-topup',

  PAYMENT_CALLBACK: '/payment',

  ESIM_TARIFF: '/esim-tariff',

  TARIFF_OPTION: '/tariff',
  TARIFF_OVERVIEW: '/tariff/overview',
  TARIFF_DETAIL: (id) => (id ? `/tariff/change/${id}` : '/tariff/change/:tariffId'),
  OPTION_OVERVIEW: '/tariff/option/overview',
  OPTION_DETAIL: (id) => (id ? `/tariff/option/book/${id}` : '/tariff/option/book/:optionId'),
  ADDITIONAL_OPTION_DETAIL: (id) => (id ? `/tariff/additional-option/book/${id}` : '/tariff/additional-option/book/:passCode'),
  PASSOFFER_DETAIL: (passCode) =>
    passCode ? `/tariff/passoffer/book/${passCode}` : '/tariff/passoffer/book/:passCode',
  AUSLAND_OVERVIEW: '/tariff/ausland/overview',

  TOPUP_AUFLADEN_OVERVIEW: '/topup/overview',
  TOPUP_ONLINE: '/topup/online',
  TOPUP_ONLINE_CALLBACK: '/topup/online/callback',
  TOPUP_VOUCHER: '/topup/voucher',
  TOPUP_VOUCHER_HISTORY: '/topup/voucher-history',
  TOPUP_VOUCHER_CALLBACK: '/topup/voucher/callback',

  HELP_SERVICES: '/help-services',
  HELP_SERVICES_OVERVIEW: '/help-services/overview',
  HELP_SERVICES_CONTACT: '/help-services/contact',
  HELP_SERVICES_FAQ: '/help-services/faq',
  HELP_SERVICES_PRIVACY_POLICY: '/help-services/privacy-policy',
  HELP_SERVICES_VIDEO: '/help-services/video',
  HELP_SERVICES_IMPRINT: '/help-services/imprint',
  HELP_SERVICES_CANCEL_CONTRACT: '/help-services/cancel-contract',

  ACCOUNT: '/account',
  ACCOUNT_OVERVIEW: '/account/overview',
  ACCOUNT_AUTO_TOPUP: '/account/auto-topup',
  ACCOUNT_DOCUMENT: '/account/docs',
  ACCOUNT_PRIVATE_DATA: '/account/info',

  ACCOUNT_PRIVATE_DATA_CHANGE_CONSENT: '/account/change-consent',
  ACCOUNT_PRIVATE_DATA_CHANGE_CONTACT_NUMBER: '/account/change-contact-number',
  ACCOUNT_PRIVATE_DATA_CHANGE_EMAIL: '/account/change-email',
  ACCOUNT_PRIVATE_DATA_CHANGE_HOTLINE_PIN: '/account/change-hotline-pin',
  ACCOUNT_PRIVATE_DATA_NEW_PASSWORD: '/account/new-password',
  ACCOUNT_PRIVATE_DATA_EVN_PDF_VIEW: (fileName) =>
    fileName ? `/account/evn-pdf-view/${fileName}` : '/account/evn-pdf-view/:fileName',

  ACCOUNT_TARIFF_OPTION: '/account/tariff-option',
  ACCOUNT_LOGOUT: '/account/logout',

  // Old
  OPTION_BOOK: (id) => (id ? `/dashboard/option/book/${id}` : '/dashboard/option/book/:optionId'),
  SPEEDON_BOOK: (id) =>
    id ? `/dashboard/speedon/book/${id}` : '/dashboard/speedon/book/:speedonId',
  PASSOFFER_BOOK: (passCode) =>
    passCode ? `/dashboard/passoffer/book/${passCode}` : '/dashboard/passoffer/book/:passCode',
  DATAPASS_BOOK: (id) =>
    id ? `/dashboard/datapass/book/${id}` : '/dashboard/datapass/book/:datapassId',
  ROAMING_BOOK: (id) =>
    id ? `/dashboard/roaming/book/${id}` : '/dashboard/roaming/book/:roamingId'
};
export const storageKeys = {
  // ...commonRoutes,
  CODE: 'code',
  SESSION_STATE: 'session_state',
  X_LOG_TAG: 'x_log_tag'
};

export const appStorage = {
  ...AppStorage,
  CART_NAME: 'cartName',
  ORDER_NUMBER: 'orderNumber',
  ACTIVATION_DATA: 'activationData',
  ACTIVATION_PERSONAL_DATA: 'activationPersonalData',
  ACTIVATION_MSISDN: 'activationMsisdn',
  POSTIDENT: 'postIdent',
  TARIFF_SHOWN_TIME: 'tarrifShownTime',
  PREFERENCE_SHOW_TIME: 'preferenceShowTime',
  CONSENT_SHOW_TIME: 'consentShowTime',
  NAVIGATE_TO: 'navigateTo',
  PAYMENT_REASON: 'paymentReason',
  TOPUP_TYPE: 'topupType',
  TOPUP_AMOUNT: 'topupAmount',
  MSISDN: 'msisdn'
};

export const appStaticContentConfig = {
  platform: 'web',
  lang: 'de',
  client: 'norma'
};

// App alert types
export const appAlert = {
  DEFAUT_TIMEOUT: 5000,
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
  SUCCESS: 'success',
  ERROR: 'danger',
  DISABLED: 'disabled'
};

// App alert types
export const appDefaultAlertMessage = {
  SUCCESS: 'success',
  ERROR: 'error',
  ERROR_NOT_FOUND: 'Not able to found your last request',
  ERROR_UNAUTH: 'Please login to continue!',
  INFO: 'info',
  WARNING: 'warning'
};

export const appIcons = {
  HOME: 'Home',
  DASHBOARD: 'Dashboard',
  RADIO_ACTIVE: 'RadioActive',

  hi5G: '/assets/icons/icon-hi-5g.svg',
  address: '/assets/icons/icon-address.svg',
  addressmint: '/assets/icons/icon-address-mint.svg',
  arrowup: '/assets/icons/icon-arrow-up.svg',
  arrowupmint: '/assets/icons/icon-arrow-up-mint.svg',
  arrowdown: '/assets/icons/icon-arrow-down.svg',
  arrowdownmint: '/assets/icons/icon-arrow-down-mint.svg',
  arrowleft: '/assets/icons/icon-arrow-left.svg',
  arrowright: '/assets/icons/icon-arrow-right.svg',
  arrowrightcolor: '/assets/icons/icon-arrow-right-dark-green.svg',
  arrowrightgrey: '/assets/icons/icon-arrow-right-grey.svg',
  loadingcolor: '/assets/icons/icon-loading-color.svg',
  bubbletopmint: '/assets/icons/icon-bubble-top-mint.svg',
  bubbletop: '/assets/icons/icon-bubble-top.svg',
  bubblemint: '/assets/icons/icon-bubble-mint.svg',
  bubblemint60top: '/assets/icons/icon-bubble-top-mint60.svg',
  bubblemint60bottom: '/assets/icons/icon-bubble-bottom-mint60.svg',
  bubble: '/assets/icons/icon-bubble.svg',
  bubbletabdanger: '/assets/icons/icon-bubble-tab-danger.svg',
  bubbletabaccent: '/assets/icons/icon-bubble-tab-accent.svg',
  back: '/assets/icons/icon-back.svg',
  backcolor: '/assets/icons/icon-back-color.svg',
  break: '/assets/icons/icon-break.svg',
  call: '/assets/icons/icon-call.svg',
  cash: '/assets/icons/icon-cash.svg',
  cashdarkgreen: '/assets/icons/icon-cash-darkgreen.svg',
  change: '/assets/icons/icon-change.svg',
  chat: '/assets/icons/icon-chat.svg',
  checkcircle: '/assets/icons/icon-check-circle.svg',
  checkcirclemint: '/assets/icons/icon-check-circle-circle-mint.svg',
  checkdarkgreen: '/assets/icons/icon-check-circle-darkgreen.svg',
  checklittle: '/assets/icons/icon-checklittle.svg',
  circlearrow: '/assets/icons/icon-circlearrow.svg',
  checked: '/assets/icons/icon-check.svg',
  close: '/assets/icons/icon-close.svg',
  closecolor: '/assets/icons/icon-close-color.svg',
  closesm: '/assets/icons/icon-close-sm.svg',
  coffee: '/assets/icons/icon-coffee.svg',
  coffeemint: '/assets/icons/icon-coffee-mint.svg',
  coins: '/assets/icons/icon-coins.svg',
  coinsmint: '/assets/icons/icon-coins-mint.svg',
  confetti: '/assets/icons/icon-confetti.svg',
  confetticolor: '/assets/icons/icon-confetti-color.svg',
  data: '/assets/icons/icon-data.svg',
  datagray100: '/assets/icons/icon-data-gray-100.svg',
  darkarrows: '/assets/icons/icon-arrows-dark.svg',
  date: '/assets/icons/icon-date.svg',
  dateGreen: '/assets/icons/icon-date-green.svg',
  datemint: '/assets/icons/icon-date-mint.svg',
  delete: '/assets/icons/icon-delete.svg',
  deletemint: '/assets/icons/icon-delete-mint.svg',
  deletedark: '/assets/icons/icon-delete-dark.svg',
  download: '/assets/icons/icon-download.svg',
  downloaddark: '/assets/icons/icon-download-dark.svg',
  downloadDarkGreen: '/assets/icons/icon-download-green.svg',
  edit: '/assets/icons/icon-edit.svg',
  editdark: '/assets/icons/icon-edit-dark.svg',
  error: '/assets/icons/icon-error-1.svg',
  errorcolor: '/assets/icons/icon-error-color.svg',
  errorwhite: '/assets/icons/icon-error.svg',
  faceid: '/assets/icons/icon-faceid.svg',
  faq: '/assets/icons/icon-faq.svg',
  forward: '/assets/icons/icon-forward.svg',
  forwarddark: '/assets/icons/icon-forward-dark.svg',
  forwardprimary: '/assets/icons/icon-forward-primary.svg',
  forwardmint: '/assets/icons/icon-forward-mint.svg',
  giftcard: '/assets/icons/icon-giftcard.svg',
  giftcarddarkgreen: '/assets/icons/icon-giftcard-darkgreen.svg',
  groupdarkgreen: '/assets/icons/icon-groupdarkgreen.svg',
  hamburger: '/assets/icons/icon-hamburger.svg',
  hamburgerclose: '/assets/icons/icon-hamburger-close.svg',
  happy: '/assets/icons/icon-happy.svg',
  happycolor: '/assets/icons/icon-happy-color.svg',
  hide: '/assets/icons/icon-hide.svg',
  highlight: '/assets/icons/icon-highlight.svg',
  history: '/assets/icons/icon-history.svg',
  historydarkgreen: '/assets/icons/icon-history-darkgreen.svg',
  info: '/assets/icons/icon-info.svg',
  infomint: '/assets/icons/icon-info-mint.svg',
  infowhite: '/assets/icons/icon-info-white.svg',
  infogray100: '/assets/icons/icon-info-gray-100.svg',
  key: '/assets/icons/icon-key.svg',
  keycolor: '/assets/icons/icon-key-color.svg',
  keydarkgreen: '/assets/icons/icon-key-darkgreen.svg',
  like: '/assets/icons/icon-like.svg',
  layerdarkgreen: '/assets/icons/icon-layer-darkgreen.svg',
  login: '/assets/icons/icon-login.svg',
  logindarkgreen: '/assets/icons/icon-login-darkgreen.svg',
  logout: '/assets/icons/icon-logout.svg',
  logoutmint: '/assets/icons/icon-logout-mint.svg',
  loupe: '/assets/icons/icon-loupe.svg',
  loupegreen: '/assets/icons/icon-loupe-green.svg',
  mail: '/assets/icons/icon-mail.svg',
  maildarkgreen: '/assets/icons/icon-mail-darkgreen.svg',
  mailmint: '/assets/icons/icon-mail-mint.svg',
  minus: '/assets/icons/icon-minus.svg',
  minusdarkgreen: '/assets/icons/icon-minus-darkgreen.svg',
  minusmint: '/assets/icons/icon-minus-mint.svg',
  minutes: '/assets/icons/icon-minutes.svg',
  minutesgray100: '/assets/icons/icon-minutes-gray-100.svg',
  password: '/assets/icons/icon-password.svg',
  passwordcolor: '/assets/icons/icon-password-color.svg',
  passwordmint: '/assets/icons/icon-password-mint.svg',
  passwordchange: '/assets/icons/icon-password-change.svg',
  passwordforgot: '/assets/icons/icon-password-forgot.svg',
  pause: '/assets/icons/icon-pause.svg',
  pausemint: '/assets/icons/icon-pause-mint.svg',
  phone: '/assets/icons/icon-phone.svg',
  phonedarkgreen: '/assets/icons/icon-phone-darkgreen.svg',
  phonecolor: '/assets/icons/icon-phone-color.svg',
  plus: '/assets/icons/icon-plus.svg',
  plusmint: '/assets/icons/icon-plus-mint.svg',
  plusdarkgreen: '/assets/icons/icon-plus-darkgreen.svg',
  playwhite: '/assets/icons/icon-play-white.svg',
  passwordchangegreen: '/assets/icons/icon-password-change-darkgreen.svg',
  refresh: '/assets/icons/icon-refresh.svg',
  refreshcolor: '/assets/icons/icon-refresh-color.svg',
  remove: '/assets/icons/icon-remove.svg',
  removedark: '/assets/icons/icon-remove-dark.svg',
  send: '/assets/icons/icon-send.svg',
  setting: '/assets/icons/icon-setting.svg',
  settingdarkgreen: '/assets/icons/icon-setting-darkgreen.svg',
  show: '/assets/icons/icon-show.svg',
  sms: '/assets/icons/icon-sms.svg',
  smsgray100: '/assets/icons/icon-sms-gray-100.svg',
  speed: '/assets/icons/icon-speed.svg',
  speedgray100: '/assets/icons/icon-speed-gray-100.svg',
  success: '/assets/icons/icon-success.svg',
  support: '/assets/icons/icon-support.svg',
  supportcolor: '/assets/icons/icon-support-dark.svg',
  target: '/assets/icons/icon-target.svg',
  tariff: '/assets/icons/icon-tariff.svg',
  tariffdarkgreen: '/assets/icons/icon-tariff-darkgreen.svg',
  time: '/assets/icons/icon-time.svg',
  voucher: '/assets/icons/icon-voucher.svg',
  wallet: '/assets/icons/icon-walltet.svg',
  walletdark: '/assets/icons/icon-wallet-dark.svg',
  walletdarkgreen: '/assets/icons/icon-wallet-darkgreen.svg',
  warning: '/assets/icons/icon-warning.svg',
  // xxlcoffee: '/assets/icons/icon-xxl-coffee.svg',
  propertybold: '/assets/icons/property-1bold.svg',
  propertylinear: '/assets/icons/property-1linear.svg',
  pdfcolor: '/assets/icons/icon-pdf-color.svg',
  // Payment icons path
  paypal: '/assets/payment-icons/icon-paypal.svg',
  creditcard: '/assets/payment-icons/icon-creditcard.svg',
  sofort: '/assets/payment-icons/icon-sofort.svg',
  americanexpress: '/assets/payment-icons/icon-amex.svg',
  sepadirectdebit: '/assets/payment-icons/icon-sepa-2.svg',
  paydirekt: '/assets/payment-icons/icon-paydirekt.svg',
  faceIdDark: '/assets/icons/icon-faceID-dark.svg',
  iconBurger: '/assets/icons/icon-hamburger-darkgreen.svg',
  iconClose: '/assets/icons/icon-hamburger-close-darkgreen.svg',
  loading: '/assets/animations/nc-loading-darkgreen.json'
};

export const appImages = {
  header404: '/assets/images/nc_grafik_header-404-uai.png',
  homePage: '/assets/images/nc_home_woman.png',
  playerBg: '/assets/images/nc_player_sample_bg.png',
  dataPasses: '/assets/images/nc_kv_frau-zitrone-1.png',
  dataPasses2: '/assets/images/nc_kv_frau-zitrone-2.png',
  popupImage: '/assets/images/nc_KV_frau-brille-gelb.png',
  optionImage: '/assets/images/nc_kv_mann-shirt-gelb_1.png',
  auslandImage: '/assets/images/nc_kv_frau-zitrone_1.png',
  teaser5gImage: '/assets/images/nc_kv_frau-zitrone-2 1.png',
  shareImage: '/assets/images/1200_630_shared_image_norma.jpg',
  logoMint: '/assets/images/logo/nc-logo_mint_web.svg',
  logoYellow: '/assets/images/logo/nc-logo_yellow_web.svg',
  alphacommLogo: '/assets/images/logo/alphacomm-logo.svg'
};

export const appButtonTypes = {
  PRIMARY: {
    DEFAULT: 'btn btn-primary btn-default',
    LIGHT: 'btn btn-primary btn-light',
    MINT: 'btn btn-primary btn-mint'
  },
  SECONDARY: {
    DEFAULT: 'btn btn-secondary btn-default',
    LIGHT: 'btn btn-secondary btn-light',
    MINT: 'btn btn-secondary btn-mint',
    DARK: 'btn btn-secondary btn-dark'
  },
  MISC: {
    DEFAULT: 'btn btn-misc btn-default',
    LIGHT: 'btn btn-misc btn-light',
    MINT: 'btn btn-misc btn-mint',
    DARK: 'btn btn-misc btn-dark'
  }
};

export const appCounterButtonTypes = {
  LIGHT: 'btn btn-counter btn-light',
  DARK: 'btn btn-counter btn-dark'
};

export const appCounterTypes = {
  LIGHT: 'counter counter-light',
  DARK: 'counter counter-dark'
};

export const appInfoBoxType = {
  WARNING: 'infobox infobox-warning',
  LIGHT: 'infobox infobox-light',
  SUCCESS: 'infobox infobox-success',
  DARK: 'infobox infobox-dark',
  TOGGLE: 'infobox infobox-toggle'
};

export const appTextFieldLabelClass = {
  V1: 'v1',
  V2: 'v2'
};

export const appTextInputClass = {
  V1: 'v1',
  V2: 'v2'
};

export const appIconsPath = {
  HOME: '/assets/images/icons/home.svg'
};

export const appButtonType = {
  PRIMARY: 'btn btn-primary',
  SECONDARY: 'btn btn-secondary',
  DANGER: 'btn btn-danger',
  LINK: 'btn btn-link p-0'
};

export const formValidation = ({
  type = DEFAULT_FIELD_VALIDATION_TYPE.STRING, //  for which type of validation required
  required = false, // for for required validations
  minLength = false, // provide min length if required
  minLengthError = DEFAULT_ERROR_MESSAGE.fieldValidation.MIN_LENGTH,
  maxLength = false, // provide min length if required
  maxLengthError = DEFAULT_ERROR_MESSAGE.fieldValidation.MAX_LENGTH,
  regex = '', // regex if you want to do matching validation
  errorMessage = DEFAULT_ERROR_MESSAGE.fieldValidation.REQUIRED, // for addtional sub types of valdation which can required messages other than required.
  validErrorMessage = DEFAULT_ERROR_MESSAGE.fieldValidation.INVALID
}) => {
  // Assign the yup schema
  // Default willl be considered as string
  let valid = {};
  switch (type) {
    case DEFAULT_FIELD_VALIDATION_TYPE.ARRAY:
      valid = yup.array();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.BOOLEAN:
      valid = yup.boolean();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.DATE:
      valid = yup.date();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.MIXED:
      valid = yup.mixed();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.NUMBER:
      valid = yup.number();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.OBJECT:
      valid = yup.object();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.STRING:
      valid = yup.string();
      break;
    default:
      valid = yup.string();
      break;
  }

  // Check if required and set error message for it.
  if (required && typeof required === 'string') {
    valid = valid.required(required);
  }

  // Check if minimum length and set error message for it.
  if (minLength) {
    valid = valid.min(minLength, minLengthError);
  }

  // Check if maximum length and set error message for it.
  if (maxLength) {
    valid = valid.max(maxLength, maxLengthError);
  }

  // Check if type is email
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.EMAIL) {
    valid = valid.email(errorMessage);
  }

  // Check if type is url
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.URL) {
    valid = valid.url(errorMessage);
  }

  // Check if type is lowe_case
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.LOWER_CASE) {
    valid = valid.lowercase(errorMessage);
  }

  // Check if type is upper_case
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.UPPER_CASE) {
    valid = valid.uppercase(errorMessage);
  }

  // Check if regex is passed throw invalid error message.
  if (regex) {
    valid = valid.matches(regex, validErrorMessage);
  }

  return valid;
};

export const inputValidation = {
  VALID: 'valid',
  INVALID: 'invalid',

  MIN_ERROR: 'min',
  MAX_ERROR: 'max',

  MIN_MAX_ERROR: 'minMax',

  UPPER_CASE_ERROR: 'uppercase',
  LOWER_CASE_ERROR: 'lowecase',

  NUMBER_ERROR: 'number',
  SPECIAL_CASE_ERROR: 'specific',

  MATCH: 'match'
};

export const appRegex = {
  ..._appRegex,
  // validateStringLength: /^[\x21-\xFF]{12,100}$/,
  // validateUpperCase: /(?=.*[A-Z])/,
  // validateLowerCase: /(?=.*[a-z])/,
  // validateNumbers: /(?=.*\d)/,
  // validateSpecialChar: /(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])/
  validatePwd:
    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])|(?=.*[a-z])(?=.*[A-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-]))([\x21-\xFF]){12,100}$/,
  validatePwdStringLength: /^.{12,100}$/,
  validatePwdUpperOrLowerCase: /(?=.*[a-zA-Z])/,
  validatePwdNumbers: /(?=.*\d)/,
  validatePwdSpecialChar: /(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])/
};

// String Length Validations
// eslint-disable-next-line no-unused-vars
export function validateStringLength(string, min = 12, max = 64) {
  // return `${string}`.length >= min && `${string}`.length <= max;
  return appRegex.validateStringLength.test(string);
  // return (
  //   appRegex.validateString.test(string) && `${string}`.length >= min && `${string}`.length <= max
  // );
}

// String Minimum Length Validations
export function validateMinStringLength(string, min = 12) {
  return `${string}`.length >= min;
}

// UpperCase Validations
export function validateUpperCase(string) {
  return appRegex.validateUpperCase.test(string);
}

// LowerCase Validations
export function validateLowerCase(string) {
  return appRegex.validateLowerCase.test(string);
}

// Number Validations
export function validateNumber(string) {
  return appRegex.validateNumbers.test(string);
}

// Special Validations
export function validateSpecial(string) {
  // const pattern = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])|(?=.*[a-z])(?=.*[A-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-]))([\x21-\xFF]){8,100}$/;
  // eslint-disable-next-line prefer-regex-literals, no-useless-escape
  // const pattern = /(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])/;
  // const pattern = /[ *?!"§$%&#_()[\]{}@*;:,\\/<>\|~+\-]/;
  return appRegex.validateSpecialChar.test(string);
  // return /(?=.*[!"§$%&#])/.test(string);
  // return appRegex.validateSpecial.test(string);
}

// Mobile Special Validations
export function validateMobileSpecial(string) {
  return /(?=.*[!$%&#])/.test(string);
  // return appRegex.validateSpecial.test(string);
}

// Password Validation
export function validatePassword(values) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'required'
      };
    }

    const hasLength = validateStringLength(values.newPassword, 12, 20);
    const hasUpperCase = validateUpperCase(values.newPassword);
    const hasLowerCase = validateLowerCase(values.newPassword);
    const hasNumber = validateNumber(values.newPassword);
    const hasSpecial = validateSpecial(values.newPassword);

    if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
          [inputValidation.MATCH]: values.newPassword === values.confirmPassword
        }
      };
    }

    if (
      (values.newPassword || values.confirmPassword) &&
      values.newPassword !== values.confirmPassword
    ) {
      return { confirmPassword: 'match_error' };
    }

    return {};
  });
  return Object.assign(...errors);
  // let errors = {};
  // Object.keys(values).forEach((controlName) => {
  //   const string = values[controlName];

  //   if (!string) {
  //     errors = {
  //       ...errors,
  //       [controlName]: 'required'
  //     }
  //     return errors;
  //   }
  //   if (controlName === 'confirmPassword') {
  //     errors = {
  //       confirmPassword: values.newPassword === string ? false : "DO_NOT_MATCH"
  //     }
  //     return errors;
  //   }

  //   const hasLength = validateStringLength(string, 8, 20);
  //   const hasUpperCase = validateUpperCase(string);
  //   const hasLowerCase = validateLowerCase(string);
  //   const hasNumber = validateNumber(string);
  //   const hasSpecial = validateSpecial(string);
  //   if (!hasLength) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasLength error"
  //     }
  //     return errors;
  //   }
  //   if (!hasUpperCase) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasUpperCase error"
  //     }
  //     return errors;
  //   }
  //   if (!hasLowerCase) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasLowerCase error"
  //     }
  //     return errors;
  //   }
  //   if (!hasNumber) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasNumber error"
  //     }
  //     return errors;
  //   }
  //   if (!hasSpecial) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasSpecial error"
  //     }
  //     return errors;
  //   }
  //   console.log(values, errors, {
  //     hasLength,
  //     hasUpperCase,
  //     hasLowerCase,
  //     hasNumber,
  //     hasSpecial,
  //   })

  //   // errors = {
  //   //   ...errors,
  //   //   [controlName]: hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
  //   //     ? false // inputValidation.VALID
  //   //     : hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial,

  //   //   [inputValidation.MIN_MAX_ERROR]: hasLength,
  //   //   [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //   //   [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //   //   [inputValidation.NUMBER_ERROR]: hasNumber,
  //   //   [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //   // }
  //   return errors;
  // })
  // return errors;
  // Confirm password validation
  // if (controlName === 'confirmPassword') {
  //   return {
  //     valid:
  //       password && string && password === string ? inputValidation.VALID : inputValidation.INVALID,
  //   };
  // }

  // const hasLength = validateStringLength(string, 8, 20);
  // const hasUpperCase = validateUpperCase(string);
  // const hasLowerCase = validateLowerCase(string);
  // const hasNumber = validateNumber(string);
  // const hasSpecial = validateSpecial(string);

  // return {
  //   error: {
  //     [controlName]: hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
  //       ? false // inputValidation.VALID
  //       : hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial,

  //     [inputValidation.MIN_MAX_ERROR]: hasLength,
  //     [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //     [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //     [inputValidation.NUMBER_ERROR]: hasNumber,
  //     [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //   }
  // };
}

// Password Validation
export function validatePasswordWithKeys(values, firstPasswordKey, confirmPasswordKey) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'required'
      };
    }

    const hasLength = validateStringLength(values[firstPasswordKey], 12, 20);
    const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
    const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
    const hasNumber = validateNumber(values[firstPasswordKey]);
    const hasSpecial = validateSpecial(values[firstPasswordKey]);

    if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
          [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
        }
      };
    }

    if (
      (values[firstPasswordKey] || values[confirmPasswordKey]) &&
      values[firstPasswordKey] !== values[confirmPasswordKey]
    ) {
      return { [confirmPasswordKey]: 'match_error' };
    }

    return {};
  });
  return Object.assign(...errors);
}
export const gettingNumberForGerman = (number = '') => {
  let num = number;
  // eslint-disable-next-line eqeqeq
  if (num.charAt(0) == 0) {
    num = num.substring(1);
  }
  return `+49${num}`;
};

export const appAutoTopUpType = {
  UNIQUE: 'UNIQUE',
  AUTOMATIC: 'AUTOMATIC'
};

export const appTopUpType = {
  UNIQUE: 'UNIQUE',
  DIRECT: 'DIRECT',
  AUTOMATIC: 'AUTOMATIC'
};

export const appTopUpTo = {
  SELF: 'SELF',
  OTHER: 'OTHER'
};

export const AutoTopUpFor = {
  ACTIVATION: 'ACTIVATION',
  LOGIN: 'LOGIN'
};

export const appAutoTopUpPeriodType = {
  LOW_BALANCE: 'LOW_BALANCE',
  ONCE_PER_MONTH: 'ONCE_PER_MONTH',
  RATE: 'FIXED_RATE'
};

export const appPaymentCertainAmount = [5, 10, 15, 20, 50];
export const appPaymentCertainDay = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28
];

export const appPaymentTokenStatus = {
  UNCONFIRMED: 'UNCONFIRMED_PAYMENT_TOKEN'
};

export const appPaymentOrderStatus = {
  CREATION_PENDING: 'creationPending'
};

export const appPaymentProductType = {
  // Currently using
  RECURRING: 'RECURRING',
  LOW_BALANCE: 'LOW_BALANCE',
  DIRECT: 'DIRECT',

  // Extended
  ALL: 'ALL',
  PREPAID: 'PREPAID',
  TOPUP: 'TOPUP',
  CASH_CODE: 'CASH_CODE',
  VOUCHER: 'VOUCHER'
};

export const appCustomCardClass = {
  PAGE_CARD: 'page-card', // 734px
  ACTIVATION_CARD: 'activation-card', // 412px
  USAGE_CARD: 'usage-card',
  TARIFF_CARD: 'tariff-card',
  ACTIVE_TARIFF_CARD: 'active-tariff-card',
  PROFILE_CARD: 'profile-card',
  OPTION_CARD: 'option-card' // 412px
};

export const appOptionsGroupIDs = {
  TARIFF_OPTION: [350],
  DATA_OPTIONS: [115, 352],
  DAYFLATS: [151],
  SPEED_ONS_PASSES: [152],
  ROAMING: {
    LANDER_ZONE_1: [153],
    LANDER_ZONE_2: [154],
    LANDER_ZONE_3: [155]
  },
  OTHER: [156] // Sprach-Optionen
};

export const appPassCodes = {
  additionalInfo: {},
  speedOnsPassCodes: [],
  dayFlatPassCodes: [],
  dayFlatUnlimitedThreshold: 10995116277760
};

export const appOptionIds = {
  etcOptions: [3011, 3012, 3013, 3014, 3015, 3016, 3024],
  additionalInfo: {
    3011: {
      legalText: 'nc_legaltext_etc_one_option_3011',
      flatrate: false
    },
    3012: {
      additionalTitle: 'Min/SMS',
      legalText: 'nc_legaltext_etc_one_option_3012',
      flatrate: true
    },
    3013: {
      legalText: 'nc_legaltext_etc_one_option_3013',
      flatrate: false
    },
    3014: {
      legalText: 'nc_legaltext_etc_one_option_3014',
      flatrate: false
    },
    3015: {
      legalText: 'nc_legaltext_etc_one_option_3015',
      flatrate: false
    },
    3016: {
      legalText: 'nc_legaltext_etc_one_option_3016',
      flatrate: false
    },
    3024: {
      legalText: 'nc_legaltext_etc_one_option_3024',
      flatrate: false
    }
  }
};

export const appPassOffersTypes = {
  DAYFLATS_LIMITED: [102],
  DAYFLATS_UNLIMITED: [103],
  SPEED_ONS_PASSES: [101]
};
export const appPassOffersSetting = {
  speedOnsPassType: [101],
  dayFlatPassType: [102, 103],
  dayFlatUnlimitedThreshold: 10995116277760
};

export const appTariffOptionSuccessType = {
  TARIFF: 'TARIFF',
  ADDITIONAL_OPTION: 'ADDITIONAL_OPTION',
  OPTION: 'OPTION',
  SPEED_ON: 'SPEED_ON'
};

export const appPdfList = {
  // PRIVACY: "accountDocumentsPrivacy",
  // TERMS: "accountDocumentsTerms",
  // PRODUCT_SHEET: "accountDocumentsProductsheets"
  PRIVACY: 'serviceDocumentsPrivacy',
  TERMS: 'serviceDocumentsLegal',
  PRODUCT_SHEET: 'serviceDocumentsPib'
};

export const appTariffBulletGroup = {
  PROMOTIONAL_DATA: 'PROMOTIONAL_DATA',
  DATA: 'DATA',
  DESCRIPTION_ONE: 'DESCRIPTION_ONE',
  DESCRIPTION_TWO: 'DESCRIPTION_TWO',
  ROAMING_INCLUDE: 'ROAMING_INCLUDE',
  OTHER: 'OTHER'
};

export const appTariffPriceType = {
  PRICE: 'price',
  STARTER_PACK_PRICE: 'starterPackPrice'
};

// MO Customer Tariff Options & Usage Status
// https://rm3.dom.de/projects/cnet/wiki/Customer_Tariff_Options_&_Usage_Status

export const appCustomerStatus = {
  NEW: 0,
  ACTIVE: 1,
  CANCELLED: 2,
  NEW_INREALISATION: 3,
  INACTIVE: 4,
  IN_CANCELLATION: 5,
  MNP2: 6,
  MNP: 7,
  MARKED_DELETED: 8
};

export const appTariffStatus = {
  ACTIVATION_PENDING: 0,
  ACTIVE: 1,
  REJECTED: 2,
  LOCKED: 3,
  LOCK_REQUESTED: 4,
  DEACTIVATION_REQUESTED: 5,
  PAUSED: 6,
  TERMINATION_REQUESTED: 7,
  UNLOCK_REQUESTED: 8,
  TERMINATED: 9,
  ACTIVATION_REQUESTED: 10,
  SENT: 11,
  CANCELLATION_REQUESTED: 12,
  CANCELLED: 13,
  NOT_AVAILABLE: 14,
  DEFECT: 15,
  EXPORTED: 16,
  IN_CHANGE: 17,
  UNKNOWN: 18,
  ERROR: 500
};

export const appTariffStatusKey = {
  ACTIVATION_PENDING: 'ACTIVATION_PENDING',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  LOCKED: 'LOCKED',
  LOCK_REQUESTED: 'LOCK_REQUESTED',
  DEACTIVATION_REQUESTED: 'DEACTIVATION_REQUESTED',
  PAUSED: 'PAUSED',
  TERMINATION_REQUESTED: 'TERMINATION_REQUESTED',
  UNLOCK_REQUESTED: 'UNLOCK_REQUESTED',
  TERMINATED: 'TERMINATED',
  ACTIVATION_REQUESTED: 'ACTIVATION_REQUESTED',
  SENT: 'SENT',
  CANCELLATION_REQUESTED: 'CANCELLATION_REQUESTED',
  CANCELLED: 'CANCELLED',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  DEFECT: 'DEFECT',
  EXPORTED: 'EXPORTED',
  IN_CHANGE: 'IN_CHANGE',
  UNKNOWN: 'UNKNOWN'
};
// Find Tariff Status Key
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const getTariffStatusKey = (statusId) => getKeyByValue(appTariffStatus, statusId);

export const appOptionStatus = {
  ACTIVATION_PENDING: 0,
  ACTIVE: 1,
  REJECTED: 2,
  LOCKED: 3,
  LOCK_REQUESTED: 4,
  DEACTIVATION_REQUESTED: 5,
  PAUSED: 6,
  TERMINATION_REQUESTED: 7,
  UNLOCK_REQUESTED: 8,
  TERMINATED: 9,
  ACTIVATION_REQUESTED: 10,
  SENT: 11,
  CANCELLATION_REQUESTED: 12,
  CANCELLED: 13,
  NOT_AVAILABLE: 14,
  DEFECT: 15,
  EXPORTED: 16,
  IN_CHANGE: 17,
  UNKNOWN: 18
};

export const appUsageType = {
  VOICE: 'VOICE',
  DATA: 'DATA',
  SMS: 'SMS',
  DATA_UNL: 'DATA_UNL',
  VOICE_UNL: 'VOICE_UNL',
  SMS_UNL: 'SMS_UNL',
  MEASUREMENT: 'MEASUREMENT'
};

export const appUsageMeasurementsUnits = {
  MB: 'MB',
  GB: 'GB',
  MIN: 'MIN',
  Minuten: 'Minuten',
  SMS: 'SMS'
};

// TDM API response type
export const internetDataUnit = {
  GB: 'GB',
  MB: 'MB',
  KB: 'KB'
};

// Method to get data in GB from required unit
export const formatBytes = (data, units) => {
  const marker = 1024; // Change to 1000 if required
  const decimal = 1; // Change as required
  if (units === internetDataUnit.KB) {
    return (data / (marker * marker)).toFixed(decimal);
  }
  if (units === internetDataUnit.MB) {
    return (data / marker).toFixed(decimal);
  }
  if (units === internetDataUnit.GB) {
    return data;
  }
  return data;
};

export const getMaximumValue = ({
  initialValue,
  remainingValue,
  initialUnit,
  remainingUnit,
  ...restData
}) => {
  let maxValue = restData != null && initialValue != null ? initialValue : 0;
  let currentValue = restData != null && remainingValue != null ? remainingValue : 0;

  if (restData != null && initialValue != null && remainingValue != null) {
    if (initialUnit.includes(internetDataUnit.GB) && remainingUnit.includes(internetDataUnit.GB)) {
      maxValue = parseFloat(maxValue);
      currentValue = parseFloat(currentValue);
    } else if (
      initialUnit.includes(internetDataUnit.MB) &&
      remainingUnit.includes(internetDataUnit.MB)
    ) {
      maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.MB);
      currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.MB);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (initialUnit.includes(internetDataUnit.GB)) {
        maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.GB);
        currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.MB);
      } else {
        maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.MB);
        currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.GB);
      }
    }
  }

  return {
    maximumValue: maxValue,
    currentValue
  };
};

export const getMaxValue = (data) => {
  let maxValue = data != null && data.initialValue != null ? data.initialValue : 0;
  let currValue = data != null && data.remainingValue != null ? data.remainingValue : 0;

  if (data != null && data.initialValue != null && data.remainingValue != null) {
    if (data.initialUnit.includes('GB') && data.remainingUnit.includes('GB')) {
      maxValue = parseFloat(maxValue);
      currValue = parseFloat(currValue);
    } else if (data.type.includes('VOICE') || data.type.includes('VOICE')) {
      maxValue = parseFloat(maxValue);
      currValue = parseFloat(currValue);
    } else if (data.type.includes('SMS') || data.type.includes('SMS')) {
      maxValue = parseFloat(maxValue);
      currValue = parseFloat(currValue);
    } else if (data.initialUnit.includes('MB') && data.remainingUnit.includes('MB')) {
      maxValue = formatBytes(parseFloat(maxValue), 'MB');
      currValue = formatBytes(parseFloat(currValue), 'MB');
    } else if (data.initialUnit.includes('GB')) {
      maxValue = formatBytes(parseFloat(maxValue), 'GB');
      currValue = formatBytes(parseFloat(currValue), 'MB');
    } else {
      maxValue = formatBytes(parseFloat(maxValue), 'MB');
      currValue = formatBytes(parseFloat(currValue), 'GB');
    }
  }

  return {
    maximumValue: maxValue,
    currValue
  };
};

export function validateEmail(string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string
  );
}

// prefix for number validations
export function validateNumberPrefix(string) {
  return /^.{1,6}$/.test(string);
}

// prefix for number validations
export function validatePhoneNumber(string) {
  return /^[0-9]{4,15}$/.test(string);
}

export const DAYFLAT_TITLE = 'DayFlat unlimited';
export const DAYFLAT_VALUE = '10.240';
export const appPassTypeID = {
  DAY_FLAT_UNLIMITED: 102
};

export const counterTDMArray = [
  {
    id: 1,
    title: 'ek_dashboard_data-volume',
    counterType: appUsageType.DATA
  },
  {
    id: 2,
    title: 'ek_dashboard_minutes',
    counterType: appUsageType.VOICE
  },
  {
    id: 3,
    title: 'ek_dashboard_sms',
    counterType: appUsageType.SMS
  }
];

export const validateYear = {
  DEFAULT_DOB_VALIDATION_YEAR: 16
};

export const displayPrice = (price) => {
  const amount = price;
  if (amount) {
    return parseFloat(amount).toFixed(2);
  }
  return 0;
};

export const appBreakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

// eslint-disable-next-line consistent-return
export const findBreakPoint = (screenSize) => {
  if (screenSize >= appBreakPoints.xxl) {
    return 'xxl';
  }
  if (screenSize >= appBreakPoints.xl) {
    return 'xl';
  }
  if (screenSize >= appBreakPoints.lg) {
    return 'lg';
  }
  if (screenSize >= appBreakPoints.md) {
    return 'md';
  }
  if (screenSize >= appBreakPoints.sm) {
    return 'sm';
  }
  if (screenSize >= appBreakPoints.xs) {
    return 'xs';
  }
};

export const validateDOB = () =>
  `${new Date().getFullYear() - validateYear.DEFAULT_DOB_VALIDATION_YEAR}/${
    new Date().getMonth() + 1
  }/${new Date().getDate()}`;

export const validateDate = (pickedDate = new Date()) => {
  // const { t } = useStaticContent();
  const today = new Date();
  const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
  return pickedDate < todayDate && pickedDate !== todayDate;
};

export const progressPrecentageValue = (minValue, maxValue) => (minValue / maxValue) * 100;

export const appTariffs = {
  // Monthly
  // TODO: Need to remove edeka the tariff ids
  TALK: 1803,
  KOMBI_S: 1812,
  KOMBI_M: 1813,
  KOMBI_L: 1814,
  KOMBI_XL: 1815,
  KOMBI_MAX: 1810,
  JAHRESTARIF_PREMIUM: 1816,
  JAHRESTARIF_START: 1811,
  TALK_JAHRESTARIF_START: 1817,

  // Norma Tariffs
  SMART_S_5G: 1307,
  SMART_M_5G: 1308,
  SMART_L_5G: 1309,

  SMART_S: 1301,
  SMART_M: 1306,
  SMART_L: 1303,
  SMART_6: 1305,

  START_LTE: 1304
};

// Payment method's name
export const appPaymentMethods = {
  PAYPAL: 'paypal',
  SOFORT: 'sofort',
  CREDIT_CARD: 'creditcard',
  PAY_DIRECT: 'paydirekt',
  SEPA_DIRECT_DEBIT: 'sepa-direct-debit',
  AMERICAN_EXPRESS: 'american-express'
};

export const faqCategory = {
  THREE_G: '3G',
  GENERAL: 'Allgemeines',
  UNLOCK: 'Freischalten',
  CUSTOMER_PORTAL: 'Kundenportal',
  TARIFE: 'Tarife',
  ESIM: 'eSIM'
};

export const birthdayBonus = {
  AVAILABLE: 'NONE'
};

export const appTokenStatus = {
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
  CANCELLED: 'cancelled'
};

export const appBubbleTextType = {
  GRAY: 'text-text-gray-100',
  WHITE: 'text-white'
};

export const appBubbleBackground = {
  MINT80: 'bg-mint-80',
  MINT60: 'bg-mint-60',
  WHITE: 'bg-white'
};

export const getAppPdfIds = () => {
  const { t, staticContentData } = useStaticContent();

  return {
    AGB: t('nc_id_agb'),
    EVN: t('nc_id_evn'),
    PRICELIST: staticContentData?.nr_pdfPricelist?.pdfId // t('nc_id_pricelist'),
  };
};

export const appBubbleTextOpenPosition = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

export const appDashboardTeaserTypes = {
  SPEED_ONS: 'SPEED_ONS',
  DATAPASSES: 'DATAPASSES',
  OTHER: 'OTHER'
};

export const appTariffFailTypes = {
  SPEEDON: 'SPEEDON',
  DAYFLAT: 'DAYFLAT',
  TARIFF: 'TARIFF',
  OPTION: 'OPTION'
};

export const appGenericSwipType = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  BLUE: 'blue'
};

export const appGrenericSwipBg = {
  RED: 'linear-gradient(90deg, #D60F19 -0.15%, #FC3 100.91%)',
  ORANGE: 'linear-gradient(90deg, #EC671B -0.15%, #FC3 100.91%)',
  GREEN: 'linear-gradient(90deg, #0FD647 -0.15%, #33FF85 100.91%)',
  BLUE: 'linear-gradient(90deg, #0F47D6 -0.15%, #3378FF 100.91%)'
};

export const appGenericTeaserType = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  BLUE: 'blue'
};

export const appGrenericTeaserBg = {
  RED: 'linear-gradient(90deg, #D60F19 -0.15%, #FC3 100.91%)',
  ORANGE: 'linear-gradient(90deg, #EC671B -0.15%, #FC3 100.91%)',
  GREEN: 'linear-gradient(90deg, #0FD647 -0.15%, #33FF85 100.91%)',
  BLUE: 'linear-gradient(90deg, #0F47D6 -0.15%, #3378FF 100.91%)'
};

export const deviceRegex = {
  ISIOS: /iPhone|iPad|iPod/i,
  ISANDRIOD: /Android/i
};

export const makeCall = (number) => {
  // this function is called when the user clicks on the support button to open call screen
  const phoneNumber = number;

  // Open the call screen
  window.location.href = `tel: + ${phoneNumber}`;
};

export const onEmailClick = (email) => {
  // this function is called when the user clicks on the support button to open call screen
  const mail = email;

  // Open the call screen
  window.location.href = `mailto:${mail}`;
};

export function generateUUID() {
  var d = new Date().getTime();
  // eslint-disable-next-line no-bitwise
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function generateRandomValue() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomValue = '';
  /* eslint-disable no-plusplus */
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomValue += characters.charAt(randomIndex);
  }

  return randomValue;
}

export const animationHandler = (element, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  // eslint-disable-next-line no-unused-vars
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
  });

export const getPlanIconName = (name) => name.replace(/-/g, '').toLowerCase();

export const isArray = (obj) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }
  return false;
};

const formatDataVolume = (value) => {
  if (Number.isInteger(value)) {
    return value.toLocaleString('de-DE');
  }

  let newValue = value.toLocaleString('de-DE', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  });
  if (newValue?.endsWith(',0')) {
    newValue = newValue.slice(0, -2);
  }
  return newValue;
};

export const calculateDataVolume = (input) => {
  if (input && input.toLowerCase().includes('mb')) {
    const value = parseFloat(
      input.toLowerCase().replace('mb', '').replace('.', '').replace(',', '.').trim()
    );
    let result = '';
    if (value >= 1000) {
      result = `${formatDataVolume(value / 1000)} GB`;
    } else {
      result = `${formatDataVolume(value)} MB`;
    }
    return result;
  }
  return input;
};

export const getDataExpiryTime = (data) => {
  const { t } = useStaticContent();

  const [expiryDate, expiryTime] = (data?.expiryTime ?? '').split(' ');
  const [hours, minutes] = (expiryTime ?? '').split(':');
  const timeline = `${t('nc_dboard_generic_card_valid_until')} ${expiryDate}${t(
    'nc_dboard_generic_card_date_time_separator'
  )} ${hours}:${minutes} ${t('nc_dboard_generic_card_time_suffix')}`;
  return timeline;
};

export const getCountersExpiryTime = (data) => {
  const { t } = useStaticContent();

  const { endDate, endTime } = data;
  const [hours, minutes] = (endTime ?? '').split('.');
  const timeline = `${t('nc_dboard_generic_card_valid_until')} ${endDate}${t(
    'nc_dboard_generic_card_date_time_separator'
  )} ${hours}:${minutes} ${t('nc_dboard_generic_card_time_suffix')}`;

  // const timeline = `${t('nc_dboard_generic_card_valid_until')} ${endDate}${t(
  //   'nc_dboard_generic_card_date_time_separator'
  // )}`;
  if (!endDate || !endTime) {
    return '';
  }
  return timeline;
};

export const isNullValue = (val) => {
  if (val === null || val === 0 || val === '0' || val === undefined || !val) return true;
  return false;
};

export const isLocalHost = () => window.location.hostname === 'localhost';

export function convertToBytes(size, unit) {
  const units = {
    B: 1,
    KB: 1000,
    MB: 1000 ** 2,
    GB: 1000 ** 3,
    TB: 1000 ** 4
  };
  return size * units[unit];
}

export function parseInput(input) {
  const [size, unit] = input.split(' ');
  return [parseFloat(size), unit];
}

export function compareStorage(input1, input2) {
  const [size1, unit1] = parseInput(input1);
  const [size2, unit2] = parseInput(input2);

  const size1InBytes = convertToBytes(size1, unit1);
  const size2InBytes = convertToBytes(size2, unit2);

  return size1InBytes >= size2InBytes;
}

export default {
  appRoute,
  storageKeys,
  appAlert,
  appDefaultAlertMessage,
  counterTDMArray,
  gettingNumberForGerman,

  validateDOB,
  progressPrecentageValue,
  getMaximumValue,
  getMaxValue
};
